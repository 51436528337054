<template>
  <div class="bg-white-900 mb-14 mt-2">
    <div class="container mx-auto">
      <base-breadcrumbs :links-list="breadcrumbsList" class="mb-6" />
      <h1
        v-if="!isShortlistCatalogsLoading"
        class="capitalize text-5xl text-black-900 mb-5"
      >
        Shortlist of `{{ shortlistCatalogs.shortlist.name }}` Catalogs
      </h1>
      <list-catalogs
        class="mb-13"
        :isShortlistPage="true"
        :isCatalogsLoading="isShortlistCatalogsLoading"
        :catalogs="shortlistCatalogs.catalogs"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

import Breadcrumbs from "@/components/base/Breadcrumbs.vue";
import TabCatalogs from "@/components/tabs/Catalogs.vue";
import { FETCH_SHORTLIST_CATALOGS } from "@/store/actions.type";

export default {
  name: "ShortlistCatalogs",
  components: {
    "base-breadcrumbs": Breadcrumbs,
    "list-catalogs": TabCatalogs,
  },
  async mounted() {
    await this.$store.dispatch(FETCH_SHORTLIST_CATALOGS, this.$route.params.id);
    Vue.set(this.breadcrumbsList, 1, {
      ...this.breadcrumbsList[1],
      text: this.shortlistCatalogs.shortlist.name,
    });
  },
  computed: {
    ...mapGetters(["isShortlistCatalogsLoading", "shortlistCatalogs"]),
  },
  data() {
    return {
      breadcrumbsList: [
        {
          href: "/shortlist",
          text: "Shortlist",
        },
        {
          href: "",
          text: "",
        },
      ],
    };
  },
};
</script>
